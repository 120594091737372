import {
    IElevatorMasterDataContractDetails,
    IElevatorMasterDataContractDetailsDto,
} from '@shared/interfaces/elevator-master-data-contract-details.interface';
import { GenericModel } from '@shared/models/generic.model';

export class ElevatorContractDetailsModel
    extends GenericModel<IElevatorMasterDataContractDetails, IElevatorMasterDataContractDetailsDto>
    implements IElevatorMasterDataContractDetails
{
    serviceLevel: string;
    startDate: string;
    endDate: string;

    constructor(data?: Partial<IElevatorMasterDataContractDetailsDto>) {
        super(data, ElevatorContractDetailsModel);

        this.serviceLevel = data?.serviceLevel ?? '';
        this.startDate = data?.startDate ?? '';
        this.endDate = data?.endDate ?? '';
    }

    get isActive(): boolean {
        const today = new Date();

        // Reset time portion of today to 00:00:00 for date-only comparison
        today.setHours(0, 0, 0, 0);

        return new Date(this.endDate) > today;
    }

    dataTransferObject(): IElevatorMasterDataContractDetailsDto {
        return {
            serviceLevel: this.serviceLevel,
            startDate: this.startDate,
            endDate: this.endDate,
        };
    }
}

import { styles } from '@shared/services/app-styles.service';

const options = {
    background: styles.layer,
};

const listbox = {
    colorScheme: {
        light: options,
        dark: options,
    },
};

export default listbox;

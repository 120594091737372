import { IUserRole } from '@shared/interfaces/user-role.interface';
import { IUser, IUserDto } from '@shared/interfaces/user.interface';
import { GenericModel } from '@shared/models/generic.model';
import { TUserEnv } from '@shared/types/user-env.type';
import { TUser } from '@shared/types/user.type';

export class UserModel extends GenericModel<IUser, IUserDto> implements IUser {
    id: string;
    email: string;
    isLoggedIn: boolean;
    termsAndConditionsAccepted: boolean;
    profilePicture: string;
    sub: string;
    username: string;
    environment: TUserEnv;

    _role?: IUserRole;

    constructor(user?: IUserDto) {
        super(user, UserModel);

        this.username = user?.username || '';
        this.email = user?.email || '';
        this.sub = user?.sub || '';
        this.environment = user?.environment || 'local';
        this.termsAndConditionsAccepted = user?.termsAndConditionsAccepted ?? false;
        this._role = user?.role;

        this.profilePicture = '';
        this.isLoggedIn = false;
    }

    get role(): TUser {
        if (this._role?.admin) return 'globalAdmin';
        if (this._role?.user) return 'technical';
        if (this._role?.fm) return 'facilityManager';
        if (this._role?.readOnly) return 'viewer';
        return 'viewer';
    }

    get originalRole(): TUser {
        if (this.getOriginalData().role.admin) return 'globalAdmin';
        if (this.getOriginalData().role.user) return 'technical';
        if (this.getOriginalData().role.fm) return 'facilityManager';
        if (this.getOriginalData().role.readOnly) return 'viewer';
        return 'viewer';
    }

    dataTransferObject(): Partial<IUserDto> {
        return {
            termsAndConditionsAccepted: this.termsAndConditionsAccepted,
        };
    }
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ISiteDto, ISiteTopologyUpdateDto } from '@app/shared/interfaces/site.interface';
import { environment } from '@env/environment';
import { IBuildingTranslationDto } from '@shared/interfaces/building-translation.interface';
import { ISiteUserDto, ISiteUserTableDto } from '@shared/interfaces/site-user.interface';
import { BuildingTranslationModel } from '@shared/models/building-translation.model';
import { SiteUserModel } from '@shared/models/site-user.model';
import { SiteModel } from '@shared/models/site.model';
import { tools } from '@utils/tools';
import { translations } from '@utils/translations';
import { catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';

const ENDPOINT = environment.api + '/sites/';

@Injectable({
    providedIn: 'root',
})
export class SitesHttpService {
    private http: HttpClient = inject(HttpClient);

    get() {
        return this.http.get<ISiteDto[]>(ENDPOINT).pipe(
            map((sites: ISiteDto[]) => {
                try {
                    return sites.map(s => new SiteModel(s));
                } catch (e) {
                    throw new Error('getSites');
                }
            }),
        );
    }

    getById(siteId: string) {
        return this.http.get<ISiteDto>(ENDPOINT + siteId).pipe(map(site => new SiteModel(site)));
    }

    create(site: SiteModel) {
        return this.http.put<ISiteDto>(ENDPOINT, site.dataTransferObject()).pipe(
            map(site => ({
                success: true,
                value: new SiteModel(site),
                message: translations.management.sites.success.add,
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(
                            error,
                            translations.management.sites.errors.add,
                        ),
                    },
                });
            }),
        );
    }

    update(site: SiteModel) {
        return this.http.put<ISiteDto>(ENDPOINT + site._id, site.dataTransferObject()).pipe(
            map(site => ({
                success: true,
                value: new SiteModel(site),
                message: translations.management.sites.success.update,
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(
                            error,
                            translations.management.sites.errors.update,
                        ),
                    },
                });
            }),
        );
    }

    delete(id: string) {
        return this.http.delete(ENDPOINT + id).pipe(
            map(() => ({
                success: true,
                message: translations.management.sites.success.delete,
            })),
            catchError(error =>
                of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(
                            error,
                            translations.management.sites.errors.delete,
                        ),
                    },
                }),
            ),
        );
    }

    getSiteUsers(siteId: string) {
        return this.http.get<ISiteUserTableDto>(ENDPOINT + siteId + '/users').pipe(
            map(response => {
                this.validateUsers(response.rows);
                return response.rows.map(u => new SiteUserModel(u)) || [];
            }),
        );
    }

    getSiteUser(siteId: string, userId: string) {
        return this.getSiteUsers(siteId).pipe(
            map(users => {
                let user: SiteUserModel | undefined = users.find(u => u.id == userId);

                if (!user) {
                    const subUsers: SiteUserModel[] = users.flatMap(user => user.subUsers || []);
                    user = subUsers.find(subUser => subUser.id === userId);
                }

                if (!user) {
                    throw new Error(`User with ID ${userId} not found in site ${siteId}`);
                }
                return user;
            }),
        );
    }

    createSiteUser(user: SiteUserModel) {
        return this.http.put<ISiteUserDto>(ENDPOINT, user.dataTransferObject()).pipe(
            map(user => ({
                value: new SiteUserModel(user),
                message: translations.users.success.add,
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.users.errors.add),
                    },
                });
            }),
        );
    }

    getBuildingTranslations(buildingId: string) {
        return this.http
            .get<IBuildingTranslationDto>(ENDPOINT + buildingId + '/translation')
            .pipe(
                map(
                    buildingTranslation =>
                        new BuildingTranslationModel(buildingTranslation, buildingId),
                ),
            );
    }

    syncSite(siteId: string) {
        return this.http
            .post<ISiteTopologyUpdateDto>(ENDPOINT + siteId + '/topology', {})
            .pipe(map(response => response.v2));
    }

    updateBuildingTranslations(siteId: string, buildingTranslation: BuildingTranslationModel) {
        return this.http.put(
            ENDPOINT + siteId + '/translation',
            buildingTranslation.dataTransferObject(),
        );
    }

    replaceElevator(siteId: string, buildingId: string, newBuildingId: string) {
        return this.http.post(ENDPOINT + siteId + `/liftGroups/${buildingId}/change`, {
            buildingId: newBuildingId,
        });
    }

    private validateUsers(users: ISiteUserDto[]): void {
        // api return user with old model (v1)
        // manually update to v2 api with id instead of _id
        users
            .filter(user => user.hasOwnProperty('_id'))
            .forEach((user: any) => {
                user.id = user._id;
                delete user._id;
            });
    }
}

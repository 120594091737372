import { computed, effect, inject, Injectable, linkedSignal, signal } from '@angular/core';
import { SitesHttpService } from '@core/http/sites-http.service';
import { environment } from '@env/environment';
import { createDataStore } from '@shared/data-store';
import { ISiteType } from '@shared/interfaces/site-type.interface';
import { SiteModel } from '@shared/models/site.model';
import { AppStorageService } from '@shared/services/app-storage.service';
import { CustomMessageService } from '@shared/services/custom-message.service';
import { translations } from '@utils/translations';

const STORAGE_NAME = environment.localStorageName + '_SITE';

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    private sitesHttpService = inject(SitesHttpService);
    private appStorage = inject(AppStorageService);
    private customMessageService = inject(CustomMessageService);

    store = createDataStore<SiteModel, '_id'>(
        {
            primaryKey: '_id',
        },
        {
            get: {
                execute: () => this.sitesHttpService.get(),
            },
            getById: {
                execute: id => this.sitesHttpService.getById(id),
            },
            create: {
                execute: site => this.sitesHttpService.create(site),
            },
            update: {
                execute: site => this.sitesHttpService.update(site),
                onSuccess: message => {
                    this.customMessageService.success(message);
                },
            },
            delete: {
                execute: id => this.sitesHttpService.delete(id),
                confirmationOptions: {
                    title: translations.management.sites.delete.title,
                    message: translations.management.sites.delete.text,
                },
                onSuccess: async message => {
                    this.customMessageService.success(message);
                    await this.store.refreshItems({ forceRefresh: true });
                    this.store.refreshItem({
                        id: this.sites()[0]._id,
                        forceRefresh: true,
                    });
                },
            },
        },
    );

    siteTypes = signal<ISiteType[]>([
        {
            value: 'CUSTOMER',
            label: translations.site_types.CUSTOMER.title,
            helper: translations.site_types.CUSTOMER.helper,
        },
        {
            value: 'PILOT',
            label: translations.site_types.PILOT.title,
            helper: translations.site_types.PILOT.helper,
        },
        {
            value: 'DEMO',
            label: translations.site_types.DEMO.title,
            helper: translations.site_types.DEMO.helper,
        },
    ]).asReadonly();
    displayedColumns = signal<(keyof SiteModel)[]>([
        'name',
        'description',
        'siteType',
    ]).asReadonly();
    columnTranslationPrefix = signal<string>(
        translations.management.sites.table._base,
    ).asReadonly();

    sites = computed(() => this.store.items().value);
    state = computed(() => this.store.items().state);

    site = linkedSignal<SiteModel | undefined>(() => this.store.item().value);
    stateSite = computed(() => this.store.item().state);
    siteLoading = computed(() => this.store.itemLoading() === this.site()!._id);

    emailNotification = computed(() => this.site()?.status == 'active');
    messagingEnabled = computed(() => this.site()?.messagingEnabled ?? false);

    constructor() {
        effect(() => {
            if (this.site()) this.appStorage.setItem(STORAGE_NAME, this.site()!._id);
        });
    }

    async fnInit(): Promise<void> {
        await this.store.refreshItems();
        if (this.sites().length) await this.setAvailableSite();
    }

    private async setAvailableSite() {
        const siteId: string = this.appStorage.getItem(STORAGE_NAME);
        const isAvailable = this.sites().some(s => s._id === siteId);
        await this.store.refreshItem({ id: isAvailable ? siteId : this.sites()[0]._id });
    }
}

import { IElevator, IElevatorDto } from '@shared/interfaces/elevator.interface';
import { ElevatorAreaModel } from '@shared/models/elevator-area.model';
import { ElevatorMasterDataModel } from '@shared/models/elevator-master-data.model';
import { GenericModel } from '@shared/models/generic.model';

export class ElevatorModel extends GenericModel<IElevator, IElevatorDto> implements IElevator {
    id: string;
    name: string;
    groupId: string;
    terminalId?: number;
    callTypeId: number;
    kens: string[];
    masterData: ElevatorMasterDataModel[];
    areas: ElevatorAreaModel[];
    comment: string;

    constructor(data?: Partial<IElevatorDto>) {
        super(data, ElevatorModel);

        this.id = data?.id ?? '';
        this.name = data?.name ?? '';
        this.groupId = data?.groupId ?? '';
        this.terminalId = data?.terminalId;
        this.callTypeId = data?.callTypeId ?? NaN;
        this.kens = data?.kens ?? [];
        this.masterData = data?.masterData?.map(item => new ElevatorMasterDataModel(item)) ?? [];
        this.areas = data?.areas?.map(item => new ElevatorAreaModel(item)) ?? [];
        this.comment = data?.comment ?? '';
    }

    get _id() {
        return this.id.split(':')[0];
    }

    dataTransferObject(): IElevatorDto {
        return {
            ...this.getOriginalData(),
            id: this.id,
            kens: this.kens,
            name: this.name,
            groupId: this.groupId,
            terminalId: this.terminalId,
            callTypeId: this.callTypeId,
            comment: this.comment,
        };
    }
}

export type TIconGoogle = (typeof GOOGLE)[number];

export type TIconPrimeNG = (typeof PRIMENG)[number];

export type TIconKONE = (typeof KONE)[number];

export type TIcon = TIconGoogle | TIconPrimeNG | TIconKONE;

export type TIconType = 'primeng' | 'kone' | 'google';

export const GOOGLE = [
    'search_off',
    'wifi_off',
    'open_in_new',
    'refresh',
    'history',
    'add_a_photo',
    'grade',
    'domain',
    'handyman',
] as const;

export const PRIMENG = [
    'pi-address-book',
    'pi-align-center',
    'pi-align-justify',
    'pi-align-left',
    'pi-align-right',
    'pi-amazon',
    'pi-android',
    'pi-angle-double-down',
    'pi-angle-double-left',
    'pi-angle-double-right',
    'pi-angle-double-up',
    'pi-angle-down',
    'pi-angle-left',
    'pi-angle-right',
    'pi-angle-up',
    'pi-apple',
    'pi-arrow-circle-down',
    'pi-arrow-circle-left',
    'pi-arrow-circle-right',
    'pi-arrow-circle-up',
    'pi-arrow-down',
    'pi-arrow-down-left',
    'pi-arrow-down-left-and-arrow-up-right-to-center',
    'pi-arrow-down-right',
    'pi-arrow-left',
    'pi-arrow-right',
    'pi-arrow-right-arrow-left',
    'pi-arrow-up',
    'pi-arrow-up-left',
    'pi-arrow-up-right',
    'pi-arrow-up-right-and-arrow-down-left-from-center',
    'pi-arrows-alt',
    'pi-arrows-h',
    'pi-arrows-v',
    'pi-asterisk',
    'pi-at',
    'pi-backward',
    'pi-ban',
    'pi-barcode',
    'pi-bars',
    'pi-bell',
    'pi-bell-slash',
    'pi-bitcoin',
    'pi-bolt',
    'pi-book',
    'pi-bookmark',
    'pi-bookmark-fill',
    'pi-box',
    'pi-briefcase',
    'pi-building',
    'pi-building-columns',
    'pi-bullseye',
    'pi-calculator',
    'pi-calendar',
    'pi-calendar-clock',
    'pi-calendar-minus',
    'pi-calendar-plus',
    'pi-calendar-times',
    'pi-camera',
    'pi-car',
    'pi-caret-down',
    'pi-caret-left',
    'pi-caret-right',
    'pi-caret-up',
    'pi-cart-arrow-down',
    'pi-cart-minus',
    'pi-cart-plus',
    'pi-chart-bar',
    'pi-chart-line',
    'pi-chart-pie',
    'pi-chart-scatter',
    'pi-check',
    'pi-check-circle',
    'pi-check-square',
    'pi-chevron-circle-down',
    'pi-chevron-circle-left',
    'pi-chevron-circle-right',
    'pi-chevron-circle-up',
    'pi-chevron-down',
    'pi-chevron-left',
    'pi-chevron-right',
    'pi-chevron-up',
    'pi-circle',
    'pi-circle-fill',
    'pi-circle-off',
    'pi-circle-on',
    'pi-clipboard',
    'pi-clock',
    'pi-clone',
    'pi-cloud',
    'pi-cloud-download',
    'pi-cloud-upload',
    'pi-code',
    'pi-cog',
    'pi-comment',
    'pi-comments',
    'pi-compass',
    'pi-copy',
    'pi-credit-card',
    'pi-crown',
    'pi-database',
    'pi-delete-left',
    'pi-desktop',
    'pi-directions',
    'pi-directions-alt',
    'pi-discord',
    'pi-dollar',
    'pi-download',
    'pi-eject',
    'pi-ellipsis-h',
    'pi-ellipsis-v',
    'pi-envelope',
    'pi-equals',
    'pi-eraser',
    'pi-ethereum',
    'pi-euro',
    'pi-exclamation-circle',
    'pi-exclamation-triangle',
    'pi-expand',
    'pi-external-link',
    'pi-eye',
    'pi-eye-slash',
    'pi-face-smile',
    'pi-facebook',
    'pi-fast-backward',
    'pi-fast-forward',
    'pi-file',
    'pi-file-arrow-up',
    'pi-file-check',
    'pi-file-edit',
    'pi-file-excel',
    'pi-file-export',
    'pi-file-import',
    'pi-file-o',
    'pi-file-pdf',
    'pi-file-plus',
    'pi-file-word',
    'pi-filter',
    'pi-filter-fill',
    'pi-filter-slash',
    'pi-flag',
    'pi-flag-fill',
    'pi-folder',
    'pi-folder-open',
    'pi-folder-plus',
    'pi-forward',
    'pi-gauge',
    'pi-gift',
    'pi-github',
    'pi-globe',
    'pi-google',
    'pi-graduation-cap',
    'pi-hammer',
    'pi-hashtag',
    'pi-headphones',
    'pi-heart',
    'pi-heart-fill',
    'pi-history',
    'pi-home',
    'pi-hourglass',
    'pi-id-card',
    'pi-image',
    'pi-images',
    'pi-inbox',
    'pi-indian-rupee',
    'pi-info',
    'pi-info-circle',
    'pi-instagram',
    'pi-key',
    'pi-language',
    'pi-lightbulb',
    'pi-link',
    'pi-linkedin',
    'pi-list',
    'pi-list-check',
    'pi-lock',
    'pi-lock-open',
    'pi-map',
    'pi-map-marker',
    'pi-mars',
    'pi-megaphone',
    'pi-microchip',
    'pi-microchip-ai',
    'pi-microphone',
    'pi-microsoft',
    'pi-minus',
    'pi-minus-circle',
    'pi-mobile',
    'pi-money-bill',
    'pi-moon',
    'pi-objects-column',
    'pi-palette',
    'pi-paperclip',
    'pi-pause',
    'pi-pause-circle',
    'pi-paypal',
    'pi-pen-to-square',
    'pi-pencil',
    'pi-percentage',
    'pi-phone',
    'pi-pinterest',
    'pi-play',
    'pi-play-circle',
    'pi-plus',
    'pi-plus-circle',
    'pi-pound',
    'pi-power-off',
    'pi-prime',
    'pi-print',
    'pi-qrcode',
    'pi-question',
    'pi-question-circle',
    'pi-receipt',
    'pi-reddit',
    'pi-refresh',
    'pi-replay',
    'pi-reply',
    'pi-save',
    'pi-search',
    'pi-search-minus',
    'pi-search-plus',
    'pi-send',
    'pi-server',
    'pi-share-alt',
    'pi-shield',
    'pi-shop',
    'pi-shopping-bag',
    'pi-shopping-cart',
    'pi-sign-in',
    'pi-sign-out',
    'pi-sitemap',
    'pi-slack',
    'pi-sliders-h',
    'pi-sliders-v',
    'pi-sort',
    'pi-sort-alpha-down',
    'pi-sort-alpha-down-alt',
    'pi-sort-alpha-up',
    'pi-sort-alpha-up-alt',
    'pi-sort-alt',
    'pi-sort-alt-slash',
    'pi-sort-amount-down',
    'pi-sort-amount-down-alt',
    'pi-sort-amount-up',
    'pi-sort-amount-up-alt',
    'pi-sort-down',
    'pi-sort-down-fill',
    'pi-sort-numeric-down',
    'pi-sort-numeric-down-alt',
    'pi-sort-numeric-up',
    'pi-sort-numeric-up-alt',
    'pi-sort-up',
    'pi-sort-up-fill',
    'pi-sparkles',
    'pi-spinner',
    'pi-spinner-dotted',
    'pi-star',
    'pi-star-fill',
    'pi-star-half',
    'pi-star-half-fill',
    'pi-step-backward',
    'pi-step-backward-alt',
    'pi-step-forward',
    'pi-step-forward-alt',
    'pi-stop',
    'pi-stop-circle',
    'pi-stopwatch',
    'pi-sun',
    'pi-sync',
    'pi-table',
    'pi-tablet',
    'pi-tag',
    'pi-tags',
    'pi-telegram',
    'pi-th-large',
    'pi-thumbs-down',
    'pi-thumbs-down-fill',
    'pi-thumbs-up',
    'pi-thumbs-up-fill',
    'pi-thumbtack',
    'pi-ticket',
    'pi-tiktok',
    'pi-times',
    'pi-times-circle',
    'pi-trash',
    'pi-trophy',
    'pi-truck',
    'pi-turkish-lira',
    'pi-twitch',
    'pi-twitter',
    'pi-undo',
    'pi-unlock',
    'pi-upload',
    'pi-user',
    'pi-user-edit',
    'pi-user-minus',
    'pi-user-plus',
    'pi-users',
    'pi-venus',
    'pi-verified',
    'pi-video',
    'pi-vimeo',
    'pi-volume-down',
    'pi-volume-off',
    'pi-volume-up',
    'pi-wallet',
    'pi-warehouse',
    'pi-wave-pulse',
    'pi-whatsapp',
    'pi-wifi',
    'pi-window-maximize',
    'pi-window-minimize',
    'pi-wrench',
    'pi-youtube',
] as const;

export const KONE = [
    'user',
    'edit',
    'book',
    'allow',
    'arrow_left',
    'arrow_right',
    'alert-triangle-filled',
    'check-circle-filled',
    'chevron_down',
    'chevron_up',
    'chevron_left',
    'chevron_right',
    'plus',
    'more',
    'more_2',
    'help',
    'disallow',
    'project',
    'building',
    'group',
    'equipment',
    'solution',
    'close',
    'search',
    'share',
    'info',
    'setting',
    'user-group',
    'swap-vertical',
    'backtop',
    'dashboard',
    'layers',
    'scan',
    'elevator-door-opening',
    'settings',
    'lock-on',
    'link',
    'delete',
    'copy',
    'document',
    'back-previous',
    'history',
    'check',
    'key',
    'check-circle',
    'bars-one-filled',
    'spinner',
    'add-circle',
    'add-circle-filled',
    'add-rectangle',
    'alarm',
    'alert-triangle',
    'api',
    'app-launcher',
    'arrow-down',
    'arrow-up',
    'autowalk',
    'beta',
    'bluetooth',
    'box',
    'briefcase',
    'brush-clear',
    'burger',
    'calendar',
    'callout',
    'camera',
    'caret-down-big',
    'caret-down-small',
    'caret-left-big',
    'caret-left-small',
    'caret-right-big',
    'caret-right-small',
    'caret-up-big',
    'caret-up-small',
    'chart-1',
    'chart-2',
    'chat',
    'check-rectangle',
    'check-rectangle-filled',
    'checklist',
    'chevron-first',
    'chevron-inwards',
    'chevron-last',
    'chevron-leftx2',
    'chevron-outwards',
    'chevron-right-circle',
    'chevron-right-circle-filled',
    'chevron-rightx2',
    'circle',
    'clock',
    'close-circle',
    'close-circle-filled',
    'close-cross',
    'close-rectangle',
    'cloud',
    'cloud-disconnected',
    'cloud-download',
    'cloud-filled',
    'cloud-upload',
    'code',
    'controls',
    'cup_rest_break',
    'dashboard-1',
    'dashboard-2',
    'desktop',
    'diagnosis',
    'dislike',
    'dislike-filled',
    'document-complete',
    'document-filled',
    'download',
    'dropdown',
    'earthquake',
    'elevator-1',
    'elevator-2',
    'elevator-door-closed',
    'elevator-door-closing',
    'elevator-door-open',
    'elevator-passenger',
    'elevator-VIP',
    'escalator',
    'feedback',
    'figma',
    'filter',
    'flag-big',
    'flag-big-filled',
    'folder-empty',
    'folder-empty-filed',
    'folder-not-empty',
    'folder-not-empty-filled',
    'forbid',
    'fork',
    'forward',
    'fullscreen-exit',
    'fullsreen',
    'globe_region',
    'globe_region-filled',
    'headphone',
    'heart',
    'heart-filled',
    'height',
    'home',
    'home-filled',
    'house-building',
    'image',
    'inbox_file_tray',
    'info-filled',
    'jump',
    'keyboard',
    'language',
    'large_screen',
    'legal',
    'legal-2',
    'light',
    'like',
    'like-filled',
    'load-scale',
    'location',
    'lock-off',
    'login',
    'logout',
    'mail',
    'maintanence-2',
    'maintenance',
    'maintenance-filled',
    'media-pause',
    'media-pause-filled',
    'media-play',
    'media-play-filled',
    'media-play-large',
    'media-stop',
    'media-stop-filled',
    'megaphone',
    'microphone',
    'minus',
    'minus-circle',
    'minus-circle-filled',
    'minus-rectangle',
    'mobile',
    'money',
    'moon',
    'moon-filled',
    'more-h',
    'more-v',
    'navi_panel_close_left',
    'navi_panel_left',
    'navi_panel_right',
    'navigation',
    'new',
    'new_release_1',
    'new_release_2',
    'note',
    'notification-bell',
    'notification-bell-filled',
    'office-building-1',
    'office-building-2',
    'paperclip-attach',
    'paste',
    'percentage',
    'pin',
    'pop_out',
    'power-onoff',
    'poweroff2',
    'project-filled',
    'pulse_247',
    'question',
    'question-rectangle',
    'question-support',
    'question-support-2',
    'read-only',
    'rectangle',
    'refresh',
    'reload',
    'report',
    'report_new',
    'reset',
    'save',
    'scan-1',
    'scan-2',
    'secured-certified',
    'send',
    'server',
    'service',
    'settings-1',
    'settings-1-filled',
    'settings-2',
    'share-link',
    'shopping_cart_empty',
    'shopping_cart_full',
    'smiley-frustrated',
    'smiley-happy',
    'smiley-like',
    'smiley-neutual',
    'smiley-sad',
    'sort',
    'sound',
    'sound-off',
    'star',
    'star-filled',
    'steer',
    'stop-sign',
    'sun',
    'sun-filled',
    'swap',
    'swap-left',
    'swap-right',
    'tag',
    'tag-filled',
    'telephone',
    'toolbox',
    'triangle',
    'txt',
    'unlink',
    'upload-export',
    'usb',
    'user-avatar',
    'user-circle',
    'user-cloud',
    'user-filled',
    'user-maintenance',
    'user-talk',
    'usergroup',
    'videocam',
    'view-grid',
    'view-list',
    'visibility-hide',
    'visibility-show',
    'warning-circle',
    'warning-circle-filled',
    'width',
    'workflow',
    'import',
    'export',
] as const;

export const TIconMapping: { [key in TIconType]: any } = {
    kone: KONE,
    primeng: PRIMENG,
    google: GOOGLE,
};

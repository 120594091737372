import {
    IElevatorMasterData,
    IElevatorMasterDataDto,
} from '@shared/interfaces/elevator-master-data.interface';
import { ElevatorContractDetailsModel } from '@shared/models/elevator-contract-details.model';
import { GenericModel } from '@shared/models/generic.model';

export class ElevatorMasterDataModel
    extends GenericModel<IElevatorMasterData, IElevatorMasterDataDto>
    implements IElevatorMasterData
{
    ken: string;
    contractDetails: ElevatorContractDetailsModel;

    constructor(data?: Partial<IElevatorMasterDataDto>) {
        super(data, ElevatorMasterDataModel);

        this.ken = data?.ken ?? '';
        this.contractDetails = new ElevatorContractDetailsModel(data?.contractDetails);
    }

    get startDate(): string {
        return this.contractDetails.startDate;
    }

    get endDate(): string {
        return this.contractDetails.endDate;
    }

    get isActive(): boolean {
        return this.contractDetails.isActive;
    }

    dataTransferObject(): IElevatorMasterDataDto {
        return {
            ken: this.ken,
            contractDetails: this.contractDetails.dataTransferObject(),
        };
    }
}

import { effect, inject, Injectable, linkedSignal, signal } from '@angular/core';
import { environment } from '@env/environment';
import { TIcon } from '@shared/components/icon';
import { ISelectionOption } from '@shared/interfaces/selection-pool.interface';
import { AppStorageService } from '@shared/services/app-storage.service';
import { AppStyles } from '@shared/services/app-styles.service';
import _primengConfig from '@styles/primeng/_primeng-config';
import { translations } from '@utils/translations';
import { PrimeNG } from 'primeng/config';

const STORAGE_NAME = environment.localStorageName + '_theme';

export type TTheme = 'light' | 'dark';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private appStorage: AppStorageService = inject(AppStorageService);
    private appStyles: AppStyles = inject(AppStyles);
    private primeng = inject(PrimeNG);

    private _current = signal<TTheme>('light');
    themeIcon = signal<TIcon>('sun');

    themes = signal<ISelectionOption<TTheme>[]>([
        { label: translations.themes.dark, value: 'dark', icon: 'moon' },
        { label: translations.themes.light, value: 'light', icon: 'sun' },
    ]);

    current = linkedSignal<ISelectionOption<TTheme>>(
        () => this.themes().find(t => t.value === this._current())!,
    );

    constructor() {
        effect(() => {
            if (!this._current()) return;

            this.appStorage.setItem(STORAGE_NAME, this._current());
            if (this._current()) {
                setTimeout(() => this.appStyles.set());
            }
        });

        this.primeng.theme.set({
            preset: _primengConfig,
            options: {
                darkModeSelector: '.dark',
                cssLayer: {
                    name: 'primeng',
                    order: 'tailwind-base, primeng, tailwind-utilities',
                },
            },
        });
    }

    fnInit() {
        const theme = this.appStorage.getItem(STORAGE_NAME) || this._current();
        this._current.set(theme);

        this.applyThemeToBodyApp();
        this.applyThemeToHtmlApp();
        this.applyThemeToMetaTag();
    }

    trigger(): void {
        if (this._current() == 'dark') {
            this._current.set('light');
            this.themeIcon.set('sun');
        } else {
            this._current.set('dark');
            this.themeIcon.set('moon');
        }

        this.applyThemeToBodyApp();
        this.applyThemeToHtmlApp();
        this.applyThemeToMetaTag();
    }

    applyThemeToBodyApp(): void {
        const body: any = document.getElementById('body');
        const bodyClasses = body.classList;
        if (this.themes().length) bodyClasses.remove(...this.themes().map(t => t.value));

        body.classList.add(this._current());
    }

    applyThemeToHtmlApp(): void {
        const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
        const htmlClasses = html.classList;
        if (this.themes().length) htmlClasses.remove(...this.themes().map(t => t.value));

        html.classList.add(this._current());
    }

    applyThemeToMetaTag(): void {
        const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
        if (themeColorMetaTag) {
            themeColorMetaTag.setAttribute('content', this.appStyles.styles().layer);
        }
    }
}

import { styles } from '@shared/services/app-styles.service';

const options = {
    borderRadius: styles.borderRadiusInput,
    borderColor: styles.primaryBackground,
    background: styles.primaryBackground,
    checked: {
        background: styles.primaryBackground,
        borderColor: styles.primaryBackground,
    },
    hover: {
        background: styles.primaryBackground,
    },
};

const togglebutton = {
    colorScheme: {
        light: options,
        dark: options,
    },
};
export default togglebutton;

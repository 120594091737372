import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { environment } from '@env/environment';
import { TBorder, TColors, TFontSize, TTextColor } from '@shared/interfaces/root-type.interface';
import { IRootVariable } from '@shared/interfaces/root-variable.interface';

@Injectable({
    providedIn: 'root',
})
export class AppStyles {
    																													// ⚠️ list auto generated via script css-variables-generator.js
	private static cssVariables: string[] = [
		'--transition-duration-0',
		'--transition-duration-75',
		'--transition-duration-100',
		'--transition-duration-150',
		'--transition-duration-200',
		'--transition-duration-300',
		'--transition-duration-500',
		'--transition-duration-700',
		'--transition-duration-1000',
		'--transition-duration',
		'--box-shadow-sm',
		'--box-shadow',
		'--box-shadow-md',
		'--box-shadow-lg',
		'--box-shadow-xl',
		'--box-shadow-2xl',
		'--box-shadow-inner',
		'--box-shadow-none',
		'--font-weight-thin',
		'--font-weight-normal',
		'--font-weight-medium',
		'--font-weight-semibold',
		'--font-weight-bold',
		'--font-weight-extrabold',
		'--font-weight-black',
		'--font-size-xs',
		'--font-size-sm',
		'--font-size-base',
		'--font-size-lg',
		'--font-size-xl',
		'--font-size-2xl',
		'--font-size-3xl',
		'--font-size-4xl',
		'--font-size-5xl',
		'--font-size-6xl',
		'--font-size-7xl',
		'--font-size-8xl',
		'--font-size-9xl',
		'--border-radius-none',
		'--border-radius-sm',
		'--border-radius',
		'--border-radius-md',
		'--border-radius-lg',
		'--border-radius-xl',
		'--border-radius-2xl',
		'--border-radius-3xl',
		'--border-radius-full',
		'--border-radius-btn',
		'--border-radius-checkbox',
		'--border-radius-input',
		'--border-radius-item',
		'--border-0',
		'--border-2',
		'--border-4',
		'--border-8',
		'--border',
		'--font-family',
		'--font-family-kone',
		'--border-color',
		'--divide-color',
		'--page-min-width',
		'--header-height',
		'--menu-width',
		'--button-height',
		'--button-height-lg',
		'--button-height-md',
		'--button-height-sm',
		'--button-height-xs',
		'--icon-size',
		'--page-margin',
		'--page-margin-sm',
		'--padding-0',
		'--padding-1',
		'--padding-2',
		'--padding-3',
		'--padding-4',
		'--padding-5',
		'--padding-6',
		'--padding-7',
		'--padding-8',
		'--padding-px',
		'--padding-0-5',
		'--padding-1-5',
		'--padding-2-5',
		'--padding-3-5',
		'--padding-xs',
		'--padding-sm',
		'--padding-md',
		'--padding-lg',
		'--padding-item',
		'--gap-page',
		'--gap-page-sm',
		'--gap-item',
		'--gap-item-xs',
		'--gap-item-sm',
		'--gap-item-lg',
		'--margin-0',
		'--margin-1',
		'--margin-2',
		'--margin-3',
		'--margin-4',
		'--margin-5',
		'--margin-6',
		'--margin-7',
		'--margin-8',
		'--margin-9',
		'--margin-10',
		'--margin-11',
		'--margin-12',
		'--margin-14',
		'--margin-16',
		'--margin-20',
		'--margin-px',
		'--margin-0-5',
		'--margin-1-5',
		'--margin-2-5',
		'--margin-3-5',
		'--margin-auto',
		'--margin-xs',
		'--margin-sm',
		'--margin-md',
		'--margin-lg',
		'--margin-page',
		'--margin-item',
		'--margin-item-xs',
		'--margin-item-sm',
		'--margin-item-lg',
		'--text-color',
		'--text-color-soft',
		'--text-color-disabled',
		'--text-color-inverse',
		'--primary-50',
		'--primary-100',
		'--primary-200',
		'--primary-300',
		'--primary-400',
		'--primary-500',
		'--primary-600',
		'--primary-700',
		'--primary-800',
		'--primary-900',
		'--primary-950',
		'--primary',
		'--primary-background',
		'--warn-50',
		'--warn-100',
		'--warn-200',
		'--warn-300',
		'--warn-400',
		'--warn-500',
		'--warn-600',
		'--warn-700',
		'--warn-800',
		'--warn-900',
		'--warn-950',
		'--warn',
		'--warn-background',
		'--orange-50',
		'--orange-100',
		'--orange-200',
		'--orange-300',
		'--orange-400',
		'--orange-500',
		'--orange-600',
		'--orange-700',
		'--orange-800',
		'--orange-900',
		'--orange-950',
		'--orange',
		'--orange-background',
		'--green-50',
		'--green-100',
		'--green-200',
		'--green-300',
		'--green-400',
		'--green-500',
		'--green-600',
		'--green-700',
		'--green-800',
		'--green-900',
		'--green-950',
		'--green',
		'--green-background',
		'--purple',
		'--teal',
		'--yellow',
		'--magenta',
		'--ice-blue',
		'--excel',
		'--btn-soft-grey',
		'--btn-soft-grey-inverse',
		'--black-white',
		'--inverse',
		'--disabled',
		'--hover-color',
		'--hover-strong-color',
		'--layer-50',
		'--layer-100',
		'--layer-200',
		'--layer-300',
		'--layer-400',
		'--layer-500',
		'--layer-600',
		'--layer-700',
		'--layer-800',
		'--layer-900',
		'--layer-950',
		'--layer',
		'--layer-secondary',
		'--layer-tertiary',
		'--layer-quaternary',
		'--font-weight-extralight',
		'--font-weight',
	];

    private _styles: WritableSignal<IRootVariable> = signal({} as IRootVariable);

    styles: Signal<IRootVariable> = this._styles.asReadonly();

    constructor() {
        window.addEventListener('load', () => {
            this.set();
        });
    }

    set(): void {
        const rootStyles = getComputedStyle(document.documentElement);
        this._styles.set(
            AppStyles.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
                acc[AppStyles.convertCssVarToCamelCase(rootVar)] = AppStyles.getRootValue(
                    rootVar,
                    rootStyles,
                );
                return acc;
            }, {}) as unknown as IRootVariable,
        );
        AppStyles.initializeStaticStyles();
        // if (!environment.production) console.log('AppStyles', this.styles(), styles);
    }

    getBorderWidth(border: TBorder): string {
        const styles: any = this.styles();
        const varName: any = this.kebabToCamelCase(border);
        return styles[varName];
    }

    getColor(key: TColors): string {
        // const color = AppStyles.getRootValue(this.getRootVarName(key));
        // return color != '' ? color : 'var(' + this.getRootVarName(key) + ')'
        const styles: any = this.styles();
        const varName: any = this.kebabToCamelCase(key);
        return styles[varName];
    }

    getTextColor(key: TTextColor): string {
        // const color = AppStyles.getRootValue(this.getRootVarName(key));
        // return color != '' ? color : 'var(' + this.getRootVarName(key) + ')';
        const styles: any = this.styles();
        const varName: any = this.kebabToCamelCase(key);
        return styles[varName];
    }

    getFontSize(fontSize: TFontSize): string {
        return this.getRootVarName(fontSize);
    }

    getRootVar(name: string): string {
        return `var(${this.getRootVarName(name)})`;
    }

    getNumberFromPixels(key: keyof IRootVariable): number {
        const value = this.styles()[key];

        // Check if the value is in rem
        if (value.includes('rem')) {
            // Assuming 1rem = 16px for conversion
            const remValue = parseFloat(value.replace('rem', ''));
            return remValue * 16; // Convert rem to px
        } else if (value.includes('px')) {
            return Number(value.replace('px', ''));
        }
        return 0;
    }

    getNumberFromTime(key: keyof IRootVariable): number {
        const pixels = this.styles()[key];
        if (typeof pixels == 'string') return Number(pixels.replace('ms', ''));
        return 0;
    }

    toOpacity(key?: keyof IRootVariable, color?: string, alpha?: number): string {
        if (key) color = this.styles()[key] as string;

        if (!color) return '';

        let r, g, b;
        if (color.includes('rgba')) {
            const split = color.split('rgba(')[1];
            const values = split.split(',');
            r = Number(values[0]);
            g = Number(values[1]);
            b = Number(values[2]);
        } else {
            color = color.toUpperCase();
            r = parseInt(color.slice(1, 3), 16);
            g = parseInt(color.slice(3, 5), 16);
            b = parseInt(color.slice(5, 7), 16);
        }

        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }

    getTextColorForBackground(color: string | TColors): string {
        const { r, g, b } = this.isRgbFormat(color)
            ? this.getRgbValues(color)
            : this.hexToRgb(color);
        return this.luminance(r, g, b) > 0.179 ? 'black' : 'white';
    }

    private getRgbValues(rgb: string) {
        const regex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
        const match = regex.exec(rgb);
        return {
            r: match ? parseInt(match[1], 10) : 0,
            g: match ? parseInt(match[2], 10) : 0,
            b: match ? parseInt(match[3], 10) : 0,
        };
    }

    private isRgbFormat(color: string) {
        // Regular expression to match the RGB format
        const regex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
        return regex.test(color);
    }

    private hexToRgb(hex: string): { r: number; g: number; b: number } {
        let parsedHex = hex.startsWith('#') ? hex.slice(1) : hex;
        if (parsedHex.length === 3) {
            parsedHex = [...parsedHex].map(x => x + x).join('');
        }
        const rgb = parseInt(parsedHex, 16);
        const r = (rgb >> 16) & 255;
        const g = (rgb >> 8) & 255;
        const b = rgb & 255;
        return { r, g, b };
    }

    private luminance(r: number, g: number, b: number): number {
        const a = [r, g, b].map(v => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    private getRootVarName(name: any) {
        return '--' + name;
    }

    private kebabToCamelCase(str: string): string {
        return str.replace(/-([a-z0-9])/g, (_, char) => char.toUpperCase());
    }

    static convertCssVarToCamelCase(cssVar: string): string {
        // Remove the initial two dashes
        const cleanVar = cssVar.substring(2);

        // Split the string into words using hyphen as separator
        const words = cleanVar.split('-');

        // Convert to camelCase: leave the first word as is, capitalize subsequent words
        return words
            .map((word, index) => {
                return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join('');
    }

    private static getRootValue(rootVar: string, rootStyles: CSSStyleDeclaration): string {
        let value: string = rootStyles.getPropertyValue(rootVar).trim();
        value = value != '' ? value : 'var(' + rootVar + ')';

        // Convert the string to a number (in seconds), then to milliseconds
        if (!value.includes('--') && value.includes('s') && !value.includes('ms')) {
            const milliseconds = parseFloat(value) * 1000;
            return milliseconds.toString() + 'ms';
        }
        return value;
    }

    public static get staticStyles(): IRootVariable {
        const rootStyles = getComputedStyle(document.documentElement);
        return this.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
            acc[this.convertCssVarToCamelCase(rootVar)] = this.getRootValue(rootVar, rootStyles);
            return acc;
        }, {}) as unknown as IRootVariable;
    }

    public static initializeStaticStyles(): void {
        styles = AppStyles.staticStyles;
    }
}

export let styles: IRootVariable = AppStyles.staticStyles;

import { IElevatorArea, IElevatorAreaDto } from '@shared/interfaces/elevator-area.interface';
import { GenericModel } from '@shared/models/generic.model';

export class ElevatorAreaModel
    extends GenericModel<IElevatorArea, IElevatorAreaDto>
    implements IElevatorArea
{
    areaId: string;
    shortName: string;
    exitFloor: boolean;

    constructor(data?: Partial<IElevatorAreaDto>) {
        super(data, ElevatorAreaModel);

        this.areaId = data?.areaId ?? '';
        this.shortName = data?.shortName ?? '';
        this.exitFloor = data?.exitFloor ?? false;
    }

    dataTransferObject(): IElevatorAreaDto {
        return {
            areaId: this.areaId,
            shortName: this.shortName,
            exitFloor: this.exitFloor,
        };
    }
}

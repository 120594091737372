import { styles } from '@shared/services/app-styles.service';

const _colorScheme = {
    chip: {
        borderRadius: styles.borderRadiusInput,
    },
};

const autocomplete = {
    colorScheme: {
        light: _colorScheme,
        dark: _colorScheme,
    },
};

export default autocomplete;

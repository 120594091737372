import { HttpErrorResponse } from '@angular/common/http';
import { translations } from '@shared/utils/translations';

export const tools = {
    isMacintosh(): boolean {
        return window.navigator.userAgent.indexOf('Mac') > -1;
    },
    isWindows(): boolean {
        return window.navigator.userAgent.indexOf('Win') > -1;
    },
    copyText(text: string) {
        navigator.clipboard.writeText(text);
    },
    orderByNewestDate<T extends Record<K, string | Date>, K extends keyof T>(
        data: T[],
        dateKey: K,
    ): T[] {
        return data.sort((a, b) => {
            const timeA = new Date(a[dateKey]).getTime();
            const timeB = new Date(b[dateKey]).getTime();
            return timeB - timeA;
        });
    },
    orderByOldestDate<T extends Record<K, string | Date>, K extends keyof T>(
        data: T[],
        dateKey: K,
    ): T[] {
        return data.sort((a, b) => {
            const timeA = a[dateKey] ? new Date(a[dateKey]).getTime() : Infinity;
            const timeB = b[dateKey] ? new Date(b[dateKey]).getTime() : Infinity;
            return timeA - timeB;
        });
    },
    orderByName<T extends Record<K, string>, K extends keyof T>(data: T[], nameKey: K): T[] {
        return data.sort((a, b) => {
            const nameA = a[nameKey].toLowerCase();
            const nameB = b[nameKey].toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    },
    getErrorTranslation(error: HttpErrorResponse, defaultTranslation: string): string {
        try {
            if (!error.error.errorCode) return defaultTranslation;
            return translations.error_codes._base + error.error.errorCode;
        } catch {
            return defaultTranslation;
        }
    },
    findDuplicates<T extends object>(arr: T[], key: keyof T): T[] {
        if (arr.length === 0 || !(key in arr[0])) {
            return [];
        }

        const valueMap = new Map<any, T[]>();

        arr.forEach(obj => {
            if (key in obj) {
                const value = obj[key];
                if (!valueMap.has(value)) {
                    valueMap.set(value, []);
                }
                valueMap.get(value)!.push(obj);
            }
        });

        return Array.from(valueMap.values())
            .filter(group => group.length > 1)
            .flat();
    },
    removeDuplicates<T extends object>(arr: T[], key: keyof T): T[] {
        if (arr.length === 0) {
            return [];
        }

        const seen = new Set();
        return arr.filter(item => {
            const value = item[key];
            if (seen.has(value)) {
                return false;
            }
            seen.add(value);
            return true;
        });
    },
    reorderArray<T>(array: T[], from?: number, to?: number): T[] {
        if (typeof from !== 'number' || typeof to !== 'number') return array;
        if (isNaN(from) || isNaN(to)) return array;
        if (from < 0 || to < 0 || from >= array.length || to > array.length) return array;

        const result = [...array];
        const [removed] = result.splice(from, 1);
        result.splice(to, 0, removed);
        return result;
    },
};

import {
    booleanAttribute,
    Component,
    computed,
    contentChild,
    effect,
    HostBinding,
    inject,
    input,
    numberAttribute,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { HeaderMiddleDirective } from '@shared/components/custom-dialog/header-middle.directive';
import { IButtonAction } from '@shared/interfaces/button-action.interface';
import { Divider } from 'primeng/divider';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'custom-dialog',
    template: `
        <ng-content></ng-content>

        @if (hasActions()) {
            <div class="footer-dialog">
                @if (hasDivider()) {
                    <p-divider layout="horizontal" styleClass="!m-0" />
                }

                <div class="flex flex-row items-center buttons">
                    @for (action of actionsOnLeft(); track $index) {
                        <custom-button
                            [class.ml-item]="!$first"
                            [type]="action.type ? action.type(action) : undefined"
                            [icon]="action.icon ? action.icon(action) : undefined"
                            [color]="action.color ? action.color(action) : undefined"
                            [label]="action.label ? action.label(action) : ''"
                            [count]="action.count ? action.count(action) : undefined"
                            [disabled]="action.disabled ? action.disabled(action) : false"
                            [loading]="action.loading ? action.loading() : false"
                            (click)="action.action()" />
                    }
                    <div class="flex-grow"></div>
                    @for (action of actions(); track $index) {
                        <custom-button
                            [class.ml-item]="!$first"
                            [type]="action.type ? action.type(action) : undefined"
                            [icon]="action.icon ? action.icon(action) : undefined"
                            [iconRight]="action.iconRight ? action.iconRight(action) : false"
                            [color]="action.color ? action.color(action) : undefined"
                            [label]="action.label ? action.label(action) : ''"
                            [count]="action.count ? action.count(action) : undefined"
                            [disabled]="action.disabled ? action.disabled(action) : false"
                            [loading]="action.loading ? action.loading() : false"
                            (click)="action.action()" />
                    }
                </div>
            </div>
        }
    `,
    styles: `
        :host {
            padding: var(--padding-md);
            padding-top: 0;
            display: block;
            overflow: auto;
            max-height: calc(100vh - 197px);
            max-width: calc(100vw - 100px);
            --padding-multiplier: 2;

            &.has-divider {
                --padding-multiplier: 3;
            }

            &.remove-body-padding {
                --padding-multiplier: 2;
            }

            &.has-actions {
                padding-bottom: calc(
                    var(--button-height) + calc(var(--padding-md) * var(--padding-multiplier))
                );
            }

            .middle-header {
                position: relative;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }

            .footer-dialog {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;

                .buttons {
                    background: var(--layer);
                    padding: var(--padding-md);
                    border-bottom-right-radius: var(--border-radius);
                    border-bottom-left-radius: var(--border-radius);
                }
            }
        }
    `,
    imports: [CustomButtonComponent, TranslateModule, Divider],
})
export class CustomDialogComponent {
    public dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);
    public translateService: TranslateService = inject(TranslateService);

    header = input.required<string>();
    actions = input<IButtonAction[]>();
    actionsOnLeft = input<IButtonAction[]>();
    minWidth = input(350, { transform: numberAttribute });
    maxWidth = input(undefined, { transform: numberAttribute });
    hasClose = input(false, { transform: booleanAttribute });
    hasDivider = input(false, { transform: booleanAttribute });
    removeBodyPadding = input(false, { transform: booleanAttribute });

    headerMiddle = contentChild(HeaderMiddleDirective);

    hasActions = computed(() => this.actions()?.length || this.actionsOnLeft()?.length);

    constructor() {
        effect(() => {
            this.dialogConfig.header = this.translateService.instant(this.header());
            this.dialogConfig.closable = this.hasClose();

            // pass header middle content to config var -> then use it in header component
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.dialogConfig['headerMiddle'] = this.headerMiddle();
        });
    }

    @HostBinding('class.has-actions')
    get _hasActions() {
        return this.hasActions();
    }

    @HostBinding('class.has-divider')
    get _hasDivider() {
        return this.hasDivider();
    }

    @HostBinding('class.remove-body-padding')
    get _removeBodyPadding() {
        return this.removeBodyPadding();
    }

    @HostBinding('style')
    get styles() {
        return {
            minWidth: this.minWidth() + 'px',
            maxWidth: this.maxWidth() + 'px',
            paddingTop: this.removeBodyPadding() ? '0' : '',
            paddingInline: this.removeBodyPadding() ? '0' : '',
        };
    }
}

import { ChangeDetectorRef, Component, computed, inject, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.interface';
import { CustomAlertComponent } from '@shared/components/custom-alert';
import { CustomDialogComponent } from '@shared/components/custom-dialog';
import { TIcon } from '@shared/components/icon';
import { IconComponent } from '@shared/components/icon/icon.component';
import { IButtonAction } from '@shared/interfaces/button-action.interface';
import { TColors } from '@shared/interfaces/root-type.interface';
import { AppStyles } from '@shared/services/app-styles.service';
import { translations } from '@shared/utils/translations';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface IHint {
    text: string;
    icon?: TIcon;
    iconColor: TColors;
}

@Component({
    selector: 'confirmation-drawer',
    template: `
        <custom-dialog [header]="title" [actions]="actions()" [hasClose]="data.hasClose">
            <div class="break-spaces">{{ text | translate }}</div>
            @if (hint) {
                <div class="text-color-soft item-margin-top flex-row align-start-center">
                    @if (hint.icon) {
                        <icon
                            [name]="hint.icon"
                            [color]="hint.iconColor"
                            class="item-margin-half-right" />
                    }
                    {{ hint.text | translate }}
                </div>
            }

            @if (data.list && data.list.length) {
                <div class="list">
                    @for (item of data.list; track item) {
                        <div>{{ item }}</div>
                    }
                </div>
            }

            @if (error()) {
                <custom-alert [message]="error()" color="warn" class="mt-item" />
            }
        </custom-dialog>
    `,
    styles: `
        .list {
            margin: 10px 10px 0;
        }

        .list div {
            margin: 5px 0;
        }
    `,
    imports: [TranslateModule, IconComponent, CustomDialogComponent, CustomAlertComponent],
})
export class ConfirmationDialogComponent {
    protected readonly translations = translations;

    public dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);
    private ref: DynamicDialogRef = inject(DynamicDialogRef);
    private appStyles: AppStyles = inject(AppStyles);
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    styles = this.appStyles.styles;
    data: IConfirmationDialog = this.dialogConfig.data;

    title: string = '';
    text: string = '';
    okBtnText: string = '';
    okBtnColor: TColors;
    hint: IHint | undefined;

    loading = signal(false);
    hideCancelButton = signal(false);
    hideOkButton = signal(false);
    error = signal('');

    actions = computed<IButtonAction[]>(() => {
        const actions: IButtonAction[] = [];

        if (!this.hideCancelButton()) {
            actions.push({
                type: () => 'tertiary',
                color: () => 'primary',
                label: () => translations.global.cancel,
                disabled: () => this.loading(),
                action: () => this.cancel(),
            });
        }

        if (!this.hideOkButton()) {
            actions.push({
                type: () => 'primary',
                color: () => this.data.okBtnColor ?? 'primary',
                label: () => (this.data.okBtnText ? this.data.okBtnText : translations.global.okay),
                loading: () => this.loading(),
                action: () => this.confirm(),
            });
        }

        return actions;
    });

    constructor() {
        this.title = this.data.title ? this.data.title : '';
        this.text = this.data.text ? this.data.text : '';
        this.okBtnText = this.data.okBtnText ? this.data.okBtnText : translations.global.okay;
        this.okBtnColor = this.data.okBtnColor ?? 'primary';
        this.hideCancelButton.set(this.data.hideCancelButton ?? false);
        this.hideOkButton.set(this.data.hideOkButton ?? false);

        if (this.data.hint) {
            this.hint = { text: '', iconColor: 'primary' };
            Object.assign(this.hint, this.data.hint);
        }
    }

    cancel() {
        this.ref.close();
    }

    async confirm(index?: number) {
        if (this.data.confirmAsyncAction) {
            this.error.set('');
            this.loading.set(true);
            this.cdr.detectChanges();
            await new Promise(resolve => setTimeout(resolve, 100));
            if (this.data.beforeConfirm) this.data.beforeConfirm();
            const response = await this.data.confirmAsyncAction();
            if (response.success) {
                if (this.data.afterSuccess) this.data.afterSuccess();
                this.ref.close(response);
            } else if (response.error) {
                this.error.set(response.error.message);
                this.loading.set(false);
                if (this.data.afterError) this.data.afterError();
            }
            this.cdr.detectChanges();
        } else {
            this.ref.close(index || index == 0 ? index : this.data.okBtnText);
        }
    }
}

import {
    afterNextRender,
    booleanAttribute,
    Component,
    computed,
    ElementRef,
    HostBinding,
    inject,
    input,
    numberAttribute,
    Signal,
    viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TButtonType } from '@shared/components/custom-button/button-type.interface';
import { TIcon } from '@shared/components/icon';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TColors } from '@shared/interfaces/root-type.interface';
import { IRootVariable } from '@shared/interfaces/root-variable.interface';
import { AppStyles, styles } from '@shared/services/app-styles.service';
import { ThemeService } from '@shared/services/theme.service';
import { BadgeModule } from 'primeng/badge';

@Component({
    selector: 'custom-button',
    templateUrl: './custom-button.component.html',
    styleUrl: './custom-button.component.scss',
    imports: [IconComponent, TranslateModule, BadgeModule],
})
export class CustomButtonComponent {
    private appStyles: AppStyles = inject(AppStyles);

    styles: Signal<IRootVariable> = this.appStyles.styles;

    theme = inject(ThemeService).current;

    title: string = ''; // used to set attribute title in button tag

    // states
    loading = input<boolean, unknown>(false, { transform: booleanAttribute });
    disabled = input<boolean, unknown>(false, { transform: booleanAttribute });
    active = input<boolean, unknown>(false, { transform: booleanAttribute });

    // button styles
    type = input<TButtonType>();
    size = input<'xs' | 'sm' | 'lg' | undefined>();
    color = input<TColors | undefined>();
    bgCustomColor = input<string>();
    textCustomColor = input<string>();
    customPadding = input<number | undefined, unknown>(undefined, { transform: numberAttribute });
    badge = input<number | undefined, unknown>(undefined, { transform: numberAttribute });
    count = input<number, unknown>(-1, { transform: numberAttribute });
    fill = input<boolean, unknown>(false, { transform: booleanAttribute }); // fill space from left to right
    fillStart = input<boolean, unknown>(false, { transform: booleanAttribute }); // fill space from left to right
    removeHover = input<boolean, unknown>(false, { transform: booleanAttribute });
    textUppercase = input<boolean, unknown>(false, { transform: booleanAttribute });
    fontSizeSm = input<boolean, unknown>(false, { transform: booleanAttribute });
    fontSizeLg = input<boolean, unknown>(false, { transform: booleanAttribute });
    removeBold = input<boolean, unknown>(false, { transform: booleanAttribute });
    border = input<boolean, unknown>(false, { transform: booleanAttribute });
    oneLine = input<boolean, unknown>(true, { transform: booleanAttribute });
    borderRadiusMd = input<boolean, unknown>(false, { transform: booleanAttribute });
    iconRight = input<boolean, unknown>(false, { transform: booleanAttribute });
    fullWidth = input<boolean, unknown>(false, { transform: booleanAttribute });
    alignStart = input<boolean, unknown>(false, { transform: booleanAttribute });

    // label
    label = input<string>();

    // icon
    icon = input<TIcon>();
    iconSize = input<number | undefined, unknown>(undefined, { transform: numberAttribute });
    iconOnly = input<boolean, unknown>(false, { transform: booleanAttribute });
    iconOnlyNoPadding = input<boolean, unknown>(false, { transform: booleanAttribute });
    iconCustomColor = input<string>();
    iconRotate = input<number, unknown>(0, { transform: numberAttribute });

    _iconSize = computed(() =>
        this.iconOnly() && !this.iconSize() ? styles.iconSize : this.iconSize(),
    );
    textColor = computed(() => this.getTextColor());
    backgroundColor = computed(() => this.getBackgroundColor());

    @HostBinding('class.custom-button') customButtonClass = true;

    @HostBinding('class.pointer-events-none')
    get pointerEventNoneClass() {
        return this.loading() || this.disabled() || this.loading();
    }

    @HostBinding('style')
    get style() {
        return {
            '--custom-button-text-color': this.textColor(),
            '--custom-button-background-color': this.backgroundColor(),
            '--custom-button-hover-color': this.getBgHoverColor(),
            '--custom-button-selected-color': this.appStyles.toOpacity(
                undefined,
                this.textColor(),
                0.18,
            ),
            '--custom-button-padding': this.customPadding() + 'px',
        };
    }

    button: Signal<ElementRef | undefined> = viewChild('button', { read: ElementRef });

    constructor() {
        afterNextRender(() => {
            this.setText();
        });
    }

    getTextColor(): string {
        if (!this.theme()) return this.color()!;

        if (this.iconCustomColor()) return this.iconCustomColor()!;
        if (this.textCustomColor()) return this.textCustomColor()!;

        const color: string = this.color()
            ? this.appStyles.getColor(this.color()!)
            : this.styles().textColor;

        switch (this.type()) {
            case 'primary':
                if (this.color() == 'btn-soft-grey') return this.styles().textColor;
                return this.color() ? '#FFFFFF' : color;
            case 'secondary':
                return this.styles().textColorInverse;
            case 'tertiary':
                return color;
            case 'quaternary':
                return this.styles().textColor;
            case 'grey':
                if (!color) return this.appStyles.getTextColorForBackground('layer');
                return color;
        }

        return color;
    }

    getBackgroundColor(): string {
        if (!this.theme()) return this.color()!;

        if (this.bgCustomColor()) return this.bgCustomColor()!;

        const color: string = this.color() ? this.appStyles.getColor(this.color()!) : '';

        switch (this.type()) {
            case undefined:
                return 'transparent';
            case 'secondary':
                return this.appStyles.getColor('black-white');
            case 'tertiary':
                return this.appStyles.toOpacity(
                    undefined,
                    color,
                    this.theme().value == 'light' ? 0.1 : 0.25,
                );
            case 'quaternary':
                return '';
            case 'grey':
                return this.styles().layer200;
        }

        return color;
    }

    setText(): void {
        setTimeout(() => {
            if (this.button())
                this.title = this.button()
                    ?.nativeElement.textContent.replace(this.icon(), '')
                    .trim();
        }, 10);
    }

    private getBgHoverColor(): string {
        switch (this.type()) {
            case 'quaternary':
                return this.appStyles.getColor(this.color() ? this.color()! : 'text-color');
            default:
                return this.textColor().includes('var')
                    ? styles.hoverColor
                    : this.appStyles.toOpacity(undefined, this.textColor(), 0.1);
        }
    }
}

<button
    class="custom-button  {{ type() }} {{ color() }}"
    [attr.title]="title || (label() ?? '' | translate)"
    [class.loading-inside]="loading()"
    [class.fill]="fill()"
    [class.fill-start]="fillStart()"
    [class.selected]="active()"
    [class.xs]="size() === 'xs'"
    [class.small]="size() === 'sm'"
    [class.large]="size() === 'lg'"
    [class.icon-only]="iconOnly()"
    [class.icon-only-no-padding]="iconOnlyNoPadding()"
    [class.custom-padding]="customPadding()"
    [class.remove-hover]="removeHover()"
    [class.text-uppercase]="textUppercase()"
    [class.text-bold]="!removeBold()"
    [class.one-line]="oneLine()"
    [class.text-base]="!fontSizeSm() && !fontSizeLg()"
    [class.text-sm]="fontSizeSm()"
    [class.text-lg]="fontSizeLg()"
    [class.rounded-md]="borderRadiusMd()"
    [class.icon-right]="iconRight()"
    [class.full-width]="fullWidth()"
    [class.align-start]="alignStart()"
    [class.disabled]="disabled() || loading() || loading()"
    [badgeDisabled]="!badge()"
    [value]="badge()!"
    pBadge
    severity="warn"
    #button>
    @if ((!iconRight() && icon()) || (loading() && !iconRight())) {
        <icon
            [name]="loading() ? 'spinner' : icon()!"
            [size]="_iconSize()"
            [customColor]="textColor()"
            [rotate]="iconRotate()"
            [class.mr-item-sm]="!iconOnly()"
            [class.pi-spin]="loading()" />
    }

    @if (!iconOnly()) {
        <div class="flex flex-row justify-start items-center z-10">
            {{ label() ?? '' | translate }}
            @if (count() > -1) {
                ({{ count() }})
            }
            <ng-content></ng-content>
        </div>
    }

    @if ((iconRight() && icon()) || (loading() && iconRight())) {
        <icon
            [name]="loading() ? 'spinner' : icon()!"
            [size]="_iconSize()"
            [customColor]="textColor()"
            [rotate]="iconRotate()"
            [class.ml-item-sm]="!iconOnly()"
            [class.pi-spin]="loading()" />
    }
</button>

import { styles } from '@shared/services/app-styles.service';

const panel = {
    toggleable: {
        header: {
            padding: styles.paddingSm + ' ' + styles.paddingMd + ' ' + styles.paddingSm,
        },
    },
    content: {
        padding: '0 ' + styles.paddingMd + ' ' + styles.paddingMd,
    },
};

export default panel;

import { inject, Signal, signal, WritableSignal } from '@angular/core';
import { CustomDialogService } from '@shared/components/custom-dialog/custom-dialog.service';
import {
    ERROR_STATE,
    ICrudApi,
    IDataStoreConfig,
    IRefreshOptions,
    IRefreshSource,
    IStateReducer,
    LOADING_SILENT_STATE,
    LOADING_STATE,
    TDataState,
} from '@shared/data-store/interfaces';
import { IDataStoreApi } from '@shared/data-store/interfaces/data-store-api';
import { IResponseItem } from '@shared/interfaces/response-item.interface';
import { translations } from '@shared/utils/translations';
import { catchError, delay, lastValueFrom, Observable, of, startWith, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export function createDataStore<T, PrimaryKey extends keyof T, Options = any>(
    config: IDataStoreConfig<T, PrimaryKey>,
    crudApi?: ICrudApi<T, PrimaryKey, Options>,
): DataStore<T, PrimaryKey, Options> {
    return new DataStore<T, PrimaryKey, Options>(config, crudApi);
}

class DataStore<T, PrimaryKey extends keyof T, Options = any> {
    private customDialogService = inject(CustomDialogService);

    private _itemsReducer: WritableSignal<IStateReducer<T[]>>;
    private _itemReducer: WritableSignal<IStateReducer<T | undefined>>;
    private _itemLoading: WritableSignal<string | 'loading'>;

    private loadingCounts: number = 0;
    private lastItemsRefreshed: number | undefined;
    private lastItemRefreshed: number | undefined;
    private currentItemsRequest$ = new Subject<void>(); // Create a subject to trigger cancellation

    // public
    items: Signal<IStateReducer<T[]>>;
    item: Signal<IStateReducer<T | undefined>>;
    itemLoading: Signal<string | 'loading'>;

    readonly api: IDataStoreApi<T, Options>;

    constructor(
        private config: IDataStoreConfig<T, PrimaryKey>,
        private crudApi?: ICrudApi<T, PrimaryKey, Options>,
    ) {
        this._itemsReducer = signal(this.itemsInitialValue());
        this._itemReducer = signal(this.itemInitialValue());
        this._itemLoading = signal<string | 'loading'>('');

        this.items = this._itemsReducer.asReadonly();
        this.item = this._itemReducer.asReadonly();
        this.itemLoading = this._itemLoading.asReadonly();

        this.api = {
            create: (item: T, options?: Options) => this._create(item, options),
            update: (item: T, options?: Options) => this._update(item, options),
            delete: (item: T, options?: Options) => this._delete(item, options),
        };
    }

    async refreshItems(refOptions?: IRefreshOptions<T[PrimaryKey]>, options?: Options) {
        if (!(refOptions?.forceRefresh || this.isDataExpired(this.lastItemsRefreshed))) {
            return;
        }
        if (!this.crudApi?.get?.execute) return;

        this._updateItems({ state: this.getStateOnRefresh(refOptions) });

        this.loadingCounts++;
        const result = await lastValueFrom(this.fetchItems(refOptions, options));
        this.loadingCounts--;

        this.completeItems(result);

        return result.value ?? [];
    }

    async refreshItem(refOptions: IRefreshOptions<T[PrimaryKey]>, options?: Options) {
        if (!(refOptions?.forceRefresh || this.isDataExpired(this.lastItemRefreshed))) {
            return undefined;
        }

        if (!this.crudApi?.getById?.execute) return;

        this._updateItem({ state: this.getStateOnRefresh(refOptions) });

        const result = await lastValueFrom(this.fetchItem(refOptions, options));

        this.completeItem(result);

        return result.value;
    }

    updateItems(updated: T[]) {
        this._updateItems({ value: updated });
    }

    updateItemsState(state: TDataState | undefined) {
        this._updateItems({ state: state });
    }

    updateItem(updated: Partial<T>, options?: { mutate: boolean }) {
        const currentInstance = this.item().value ?? {};
        const mutate = options?.mutate ?? false;

        // - If mutate is true (default), it mutates the existing object and then updates the item.
        // - If mutate is false, it creates a new object, assigns the updated properties, and updates
        //   the item with the new object to ensure change detection.
        if (mutate) {
            Object.assign(currentInstance, updated);
        } else {
            const newInstance = Object.create(Object.getPrototypeOf(currentInstance));

            // Copy all existing properties from the current instance to the new instance
            Object.assign(newInstance, currentInstance, updated);

            // Update the item with the new instance to trigger change detection
            this._updateItem({ value: newInstance });
        }
    }

    updateItemState(state: TDataState | undefined) {
        this._updateItem({ state: state });
    }

    clear() {
        this.clearItems();
        this.clearItem();
    }

    clearItems() {
        this._updateItems(this.itemsInitialValue());
    }

    clearItem() {
        this._updateItem(this.itemInitialValue());
    }

    private async _create(item: T, options?: Options): Promise<IResponseItem<T>> {
        if (!this.crudApi?.create?.execute) return {};
        this._itemLoading.set('loading');
        const response = await lastValueFrom(
            this.crudApi.create?.execute(item, options).pipe(delay(250)),
        );

        if (response.value) {
            response.value =
                this.crudApi.create.computed && response.value
                    ? (this.crudApi.create.computed(response.value) as T)
                    : response.value;
            this.updateItems([...this.items().value, response.value]);
            if (this.crudApi?.create?.onSuccess)
                this.crudApi.create?.onSuccess(response.message, options);
        }

        if (response.error) {
            if (this.crudApi?.create?.onError)
                this.crudApi.create?.onError(response.error.message, options);
        }

        this._itemLoading.set('');
        return response;
    }

    private async _update(item: T, options?: Options): Promise<IResponseItem<T>> {
        if (!this.crudApi?.update) return {};
        this._itemLoading.set((item[this.config.primaryKey] ?? '').toString());
        const response = await lastValueFrom(
            this.crudApi.update.execute(item, options).pipe(delay(250)),
        );

        if (response.value) {
            response.value =
                this.crudApi.update.computed && response.value
                    ? (this.crudApi.update.computed(response.value) as T)
                    : response.value;
            Object.assign(item ?? {}, response.value);
            if (this.crudApi?.update?.onSuccess)
                this.crudApi.update?.onSuccess(response.message, options);
        }

        if (response.error) {
            if (this.crudApi?.update?.onError)
                this.crudApi.update?.onError(response.error.message, options);
        }

        this._itemLoading.set('');
        return response;
    }

    private async _delete(item: T, options?: Options): Promise<IResponseItem<T>> {
        if (!this.crudApi?.delete) return {};

        let response: IResponseItem<T> | undefined;

        const confirmationOptions = this.crudApi.delete.confirmationOptions;
        if (confirmationOptions) {
            const key: keyof T = confirmationOptions.title as keyof T;
            let title: string = item[key] ? item[key]!.toString() : '';
            if (!title) title = confirmationOptions.title?.toString() ?? '';
            if (!title) title = translations.global.delete;

            response = await this.customDialogService.confirm(
                {},
                {
                    title: title,
                    text: confirmationOptions.message,
                    okBtnText: translations.global.delete,
                    okBtnColor: 'warn',
                    confirmAsyncAction: async (): Promise<IResponseItem<T>> =>
                        await lastValueFrom(
                            this.crudApi!.delete!.execute(item[this.config.primaryKey]),
                        ),
                },
            );
        } else {
            this._itemLoading.set((item[this.config.primaryKey] ?? '').toString());
            response = await lastValueFrom(
                this.crudApi.delete.execute(item[this.config.primaryKey]),
            );
            this._itemLoading.set('');
        }

        if (response?.success) {
            this._updateItems({
                value: [
                    ...this.items().value.filter(
                        c => c[this.config.primaryKey] !== item[this.config.primaryKey],
                    ),
                ],
            });
            if (this.crudApi?.delete?.onSuccess)
                this.crudApi.delete?.onSuccess(response.message, options);
        }

        if (response?.error) {
            if (this.crudApi?.delete?.onError)
                this.crudApi.delete?.onError(response.error.message, options);
        }

        return response ?? {};
    }

    private _updateItems(updated: Partial<IStateReducer<T[]>>) {
        this._itemsReducer.update(current => ({
            ...current,
            ...updated,
        }));
    }

    private _updateItem(updated: Partial<IStateReducer<T | undefined>>) {
        this._itemReducer.update(current => ({
            ...current,
            ...updated,
        }));
    }

    private getStateOnRefresh(
        options?: IRefreshOptions | IRefreshOptions<T[PrimaryKey]>,
    ): TDataState {
        if (options?.loadingSilent) return LOADING_SILENT_STATE;
        else return LOADING_STATE;
    }

    private fetchItems(
        refOptions?: IRefreshOptions<T[PrimaryKey]>,
        options?: Options,
    ): Observable<IRefreshSource<T[], T[PrimaryKey]>> {
        this.currentItemsRequest$.next();
        const id = (refOptions?.id ?? '') as T[PrimaryKey];
        return this.crudApi!.get!.execute(id, options).pipe(
            takeUntil(this.currentItemsRequest$),
            map(item => ({ value: item, options: refOptions })),
            catchError(() => this.handleItemsError()),
            startWith({ value: [], options: refOptions }),
        );
    }

    private fetchItem(
        refOptions: IRefreshOptions<T[PrimaryKey]>,
        options?: Options,
    ): Observable<IRefreshSource<T | undefined, T[PrimaryKey]>> {
        if (!refOptions.id) return of({ value: undefined }).pipe(delay(350));

        const item: T | undefined = refOptions.forceRefresh
            ? undefined
            : this.items().value.find(n => n[this.config.primaryKey] == refOptions.id);

        if (refOptions.clear) this._updateItem({ value: undefined });

        if (!refOptions.loadingSilent && item) {
            return of({ value: item });
        } else {
            return this.crudApi!.getById!.execute(refOptions.id, options).pipe(
                map(item => {
                    const itemInArray: any = this.items().value.find(
                        i => item && i[this.config.primaryKey] === item[this.config.primaryKey],
                    );
                    // force update item data in array
                    if (itemInArray && item && typeof itemInArray.update === 'function') {
                        itemInArray.update(item);
                    }
                    return { value: item, options: refOptions };
                }),
                catchError(() => this.handleItemError(refOptions.id)),
            );
        }
    }

    private handleItemsError(): Observable<IRefreshSource<T[], T[PrimaryKey]>> {
        this._updateItems({ callAttempts: this.items().callAttempts + 1 });

        // send error
        if (!this.items().value.length) {
            this._updateItems({ state: ERROR_STATE });
            if (this.items().callAttempts > 1 && this.crudApi?.get?.onError) {
                this.crudApi.get?.onError();
            }
        } else if (this.crudApi?.get?.onError) {
            this.crudApi.get?.onError();
        }

        return of({ value: this.items().value });
    }

    private handleItemError(
        id?: T[PrimaryKey],
    ): Observable<IRefreshSource<T | undefined, T[PrimaryKey]>> {
        this._updateItem({ callAttempts: this.item().callAttempts + 1 });

        // send error
        if (!this.item().value) {
            this._updateItem({ state: ERROR_STATE });
            if (this.item().callAttempts > 1 && this.crudApi?.getById?.onError) {
                this.crudApi.getById?.onError();
            }
        } else if (this.crudApi?.getById?.onError) {
            this.crudApi.getById?.onError();
        }

        const item = this.item().value;
        let data = this.config.initialItemValue;
        if (item && item[this.config.primaryKey] === id) {
            data = this.item().value;
        }
        return of({ value: data }).pipe(delay(250));
    }

    private completeItems(state: IRefreshSource<T[], T[PrimaryKey]>): void {
        this._updateItems({
            state:
                this.items().state !== ERROR_STATE && this.loadingCounts <= 0
                    ? ''
                    : this.items().state,
            value: this.crudApi?.get?.computed
                ? this.crudApi.get.computed(state.value)
                : state.value,
            callAttempts: this.items().state === ERROR_STATE ? this.items().callAttempts : 0,
        });
        this.lastItemsRefreshed = Date.now();
        if (!this.items().state && this.crudApi?.get?.onSuccess) this.crudApi.get?.onSuccess();
    }

    private completeItem(state: IRefreshSource<T | undefined, T[PrimaryKey]>): void {
        this._updateItem({
            value:
                this.crudApi?.getById?.computed && state.value
                    ? this.crudApi.getById.computed(state.value)
                    : state.value,
            state: this.item().state !== ERROR_STATE ? '' : this.item().state,
            callAttempts: this.item().state === ERROR_STATE ? this.item().callAttempts : 0,
        });
        this.lastItemRefreshed = Date.now();
        if (!this.item().state && this.crudApi?.getById?.onSuccess) {
            this.crudApi.getById.onSuccess();
        }
    }

    private itemsInitialValue(): IStateReducer<T[]> {
        return {
            state: '',
            value: this.config.initialItemsValue ?? [],
            callAttempts: 0,
        };
    }

    private itemInitialValue(): IStateReducer<T | undefined> {
        return {
            state: '',
            value: this.config.initialItemValue,
            callAttempts: 0,
        };
    }

    private isDataExpired(lastRefreshed: number | undefined): boolean {
        if (!lastRefreshed) return true;

        const timeToCheck: number = 7 * 1000; // 7 seconds
        const currentTime: number = Date.now();
        return currentTime - lastRefreshed > timeToCheck;
    }
}

import { styles } from '@shared/services/app-styles.service';

const _colorScheme = {
    remove: {
        iconSize: '1.2rem',
    },
    background: styles.layerSecondary,
    color: styles.textColor,
};

const chip = {
    colorScheme: {
        light: _colorScheme,
        dark: _colorScheme,
    },
};

export default chip;

import { IPhoneNumber, IPhoneNumberDto } from '@shared/interfaces/user-phone-number.interface';
import { GenericModel } from '@shared/models/generic.model';

export class UserPhoneNumberModel
    extends GenericModel<IPhoneNumber, IPhoneNumberDto>
    implements IPhoneNumber
{
    code: string;
    number: string;

    constructor(phone?: IPhoneNumberDto) {
        super(phone, UserPhoneNumberModel);

        this.code = phone?.countryCode ?? '';
        this.number = phone?.number ?? '';
    }

    get fullNumberFormatted(): string {
        return this.code && this.number ? this.code + '-' + this.number : '';
    }

    get fullNumber(): string {
        return this.code && this.number ? this.code + this.number : '';
    }

    dataTransferObject(): IPhoneNumberDto {
        return {
            countryCode: this.code.toString(),
            number: this.number.toString(),
        };
    }
}

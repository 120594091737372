import {
    IAreaTranslation,
    IAreaTranslationDto,
    IBuildingTranslation,
    IBuildingTranslationDto,
    ITranslation,
    ITranslationDto,
} from '@shared/interfaces/building-translation.interface';
import { GenericModel, GenericObjectModel } from '@shared/models/generic.model';

export class BuildingTranslationModel
    extends GenericModel<IBuildingTranslation, IBuildingTranslationDto>
    implements IBuildingTranslation
{
    _id: string;
    buildingId: string;
    buildingName: TranslationModel;
    areas: AreaTranslationModel;

    constructor(buildingTranslation?: IBuildingTranslationDto, buildingId?: string) {
        super(buildingTranslation, BuildingTranslationModel);
        this._id = buildingTranslation?._id || buildingId || '';
        this.buildingId = buildingTranslation?.buildingId || buildingId || '';
        this.buildingName = new TranslationModel(buildingTranslation?.buildingName);
        this.areas = new AreaTranslationModel(buildingTranslation?.areas);
    }

    dataTransferObject(): IBuildingTranslationDto {
        const buildingTranslationDto: IBuildingTranslationDto = this.getOriginalData();

        buildingTranslationDto._id = this._id;
        buildingTranslationDto.buildingId = this.buildingId;
        buildingTranslationDto.buildingName = this.buildingName.dataTransferObject();
        buildingTranslationDto.areas = this.areas.dataTransferObject();

        return buildingTranslationDto;
    }
}

export class TranslationModel
    extends GenericObjectModel<ITranslation, ITranslationDto>
    implements ITranslation
{
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [key: string]: string;

    constructor(translation?: ITranslationDto) {
        super(translation);

        for (const key in translation) {
            this[key] = translation[key];
        }
    }
}

export class AreaTranslationModel
    extends GenericObjectModel<IAreaTranslation, IAreaTranslationDto>
    implements IAreaTranslation
{
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [key: string]: TranslationModel;

    constructor(areaTranslation?: IAreaTranslationDto) {
        super(areaTranslation);

        for (const key in areaTranslation) {
            this[key] = new TranslationModel(areaTranslation[key]);
        }
    }
}
